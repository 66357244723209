/* eslint-disable operator-linebreak */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
// require('dotenv').config();

import React, { Suspense, lazy } from 'react';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import PrivateRoutes from './PrivateRoutes';

const PageNotFound = lazy(() => import('./Pages/Auth/PageNotFound'));
const LoginPage = lazy(() => import('./Pages/LoginPage'));
const AccuracyTimelinessPage = lazy(() =>
  import('./Pages/AccuracyTimelinessPage'),
);
const BackReportPage = lazy(() => import('./Pages/BackReportPage'));
const CompletenessPage = lazy(() => import('./Pages/CompletenessPage'));
const MiddlewarePage = lazy(() => import('./Pages/MiddlewarePage'));
const ReferenceDataPage = lazy(() => import('./Pages/ReferenceDataPage'));
const ENOPage = lazy(() => import('./Pages/ENOPage'));
const SettingsPage = lazy(() => import('./Pages/SettingsPage'));
const RegistrationPage = lazy(() => import('./Pages/RegistrationPage'));
const ForgotPasswordPage = lazy(() => import('./Pages/ForgotPasswordPage'));
// const FileStorage = lazy(() => import('./Components/FileStorageComponent'));
const FileStoragePage = lazy(() => import('./Pages/FileStoragePage'));
const UserManagementPage = lazy(() => import('./Pages/UserManagementPage'));
const CreateFirmPage = lazy(() => import('./Pages/CreateFirmPage'));
const CreateUserPage = lazy(() => import('./Pages/CreateUserPage'));
const ShowFirmPage = lazy(() => import('./Pages/ShowFirmPage'));
const ShowUsersPage = lazy(() => import('./Pages/ShowUsersPage'));
const MapProductsToUsersPage = lazy(() => import('./Pages/MapProductsToUsersPage'));
const AdminFirmMappingPage = lazy(() => import('./Pages/AdminFirmMappingPage'));
// const ENOReportComponent = lazy(() => import('./Components/ENOReportComponent'));

function Routes() {
  return (
    <Router>
      <Suspense fallback="">
        <Switch>
          <Route exact path="/" component={LoginPage} />
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/registration-page" component={RegistrationPage} />
          <Route exact path="/forgot-password" component={ForgotPasswordPage} />
          <PrivateRoutes
            exact
            path="/accuracy-timeliness"
            component={AccuracyTimelinessPage}
          />
          <PrivateRoutes exact path="/back-report" component={BackReportPage} />
          <PrivateRoutes
            exact
            path="/completeness"
            component={CompletenessPage}
          />
          <PrivateRoutes exact path="/middleware" component={MiddlewarePage} />
          <PrivateRoutes
            exact
            path="/reference-data"
            component={ReferenceDataPage}
          />
          {/* <PrivateRoutes
            exact
            path="/eno-analytics/report"
            component={ENOReportComponent} /> */}
          <PrivateRoutes
            exact
            path="/eno-analytics"
            component={ENOPage} />
          <PrivateRoutes
            exact
            path="/settings"
            component={SettingsPage}
          />
          <PrivateRoutes
            exact
            path="/file-storage"
            component={FileStoragePage}
          />
          <PrivateRoutes
            exact
            path="/user-management"
            component={UserManagementPage}
          />
          <PrivateRoutes
            exact
            path="/create-firm"
            component={CreateFirmPage}
          />
          <PrivateRoutes
            exact
            path="/create-user"
            component={CreateUserPage}
          />
          <PrivateRoutes
            exact
            path="/show-firm"
            component={ShowFirmPage}
          />
          <PrivateRoutes
            exact
            path="/show-users"
            component={ShowUsersPage}
          />
          <PrivateRoutes
            exact
            path="/map-products-to-users"
            component={MapProductsToUsersPage}
          />
          <PrivateRoutes
            exact
            path="/admin-firm-mapping"
            component={AdminFirmMappingPage}
          />
          <Route path="*" component={PageNotFound} />
        </Switch>
      </Suspense>
    </Router>
  );
}

export default Routes;
